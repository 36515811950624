<template>
  <input @blur="onBlur" :placeholder="placeholder" @focus="onFocus" @input="onInput" :maxlength="maxlength"/>
</template>
<script>

export default {
  name: 'sdk-input',
  components: {},
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: 24
    },
  },
  data() {
    return {};
  },
  created() {
  },
  methods: {
    onInput(event) {
      this.$emit('input', event);
    },
    onBlur(event) {
      this.$emit('blur', event);
    },
    onFocus(event) {
      this.$emit('focus', event);
    }
  },
};
</script>
<style scoped lang="less">
@import '~@/styles/common.less';

input {
  outline: 0;
  border: 0;
  background: transparent;
  color: #264373;
  width: 100%;
  height: 100%
}

</style>
